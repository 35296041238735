import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [0];

export const dictionary = {
		"/": [16],
		"/(preferences)/account-management": [57,[10]],
		"/(apis)/api-aws": [~21],
		"/(apis)/api-notifications/[...passthrough]": [~22],
		"/(apis)/api-users/[...passthrough]": [~23],
		"/(apis)/api/auth/logout": [~19],
		"/(apis)/api/auth/[provider=string]/login": [~18],
		"/(apis)/api/notification-token": [~20],
		"/(apis)/api/[...passthrough]": [~17],
		"/auth/2fa/setup": [64,[14]],
		"/auth/2fa/verify": [65,[14]],
		"/auth/password/reset/confirm/[uid]/[token]": [66,[14]],
		"/auth/registration/account-confirm-email/[key]": [67,[14]],
		"/auth/reset-password": [68,[14]],
		"/(controls)/controls/[...id]": [24],
		"/(devices-hub)/devices-hub": [25,[2]],
		"/(devices-hub)/devices-hub/install": [26,[2]],
		"/(devices-hub)/devices-hub/replace": [27,[2]],
		"/(onboarding-hub)/email": [53,[9]],
		"/help": [69,[15]],
		"/(hub)/insights": [28,[3]],
		"/(internal-tools)/internal/plug-renders": [50],
		"/(internal-tools)/internal/ruleset-mass-deploy/[id_locationgroup]": [51],
		"/(intranet)/intranet": [52,[8]],
		"/login": [70],
		"/login/register": [71],
		"/login/register/resend-email": [72],
		"/login/reset-password": [73],
		"/(preferences)/notifications-preferences": [58,[11]],
		"/(onboarding-hub)/onboarding-hub": [54,[9]],
		"/(onboarding-hub)/onboarding-hub/email/wifi-details": [~56],
		"/(onboarding-hub)/onboarding-hub/[id_locationgroup]/orders/[id_order]": [55,[9]],
		"/(hub)/profile": [29,[3]],
		"/(redirect-service)/redirect": [~60],
		"/(hub)/resources/locations": [30,[3,4]],
		"/(hub)/resources/locations/[id_locationgroup=integer]": [31,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/api-keys": [32,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/asset-register": [33,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/fleet-management": [34,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/groups": [35,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/groups/[attributeName=string]": [36,[3,4,5,6]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/groups/[attributeName=string]/[attributeValue=string]": [37,[3,4,5,6,7]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/location-management": [38,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/off-times-combined": [39,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/rulesets": [40,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/status-check": [41,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/user-role-overview": [42,[3,4,5]],
		"/(hub)/resources/locations/[id_locationgroup=integer]/user-roles": [43,[3,4,5]],
		"/(hub)/resources/organisations": [44,[3]],
		"/(hub)/resources/organisations/[id_org=integer]": [45,[3]],
		"/(hub)/resources/roles": [46,[3]],
		"/(hub)/resources/users": [47,[3]],
		"/(hub)/resources/users/[email]": [48,[3]],
		"/(surveys-hub)/surveys-hub": [61,[13]],
		"/(surveys-hub)/surveys-hub/[id_locationgroup]": [62,[13]],
		"/(preferences)/user-preferences": [59,[12]],
		"/(hub)/user-profiles/[id_user_profile=integer]": [49,[3]],
		"/[...path]": [63]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';